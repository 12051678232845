import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedPosts from "../components/featured-posts";
import { Container, Typography } from '@mui/material';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" noindex />
    <Container sx={{ my: 4 }}>
      <Typography component="h1" variant="h2" gutterBottom>Oops! Looks like this is a broken link.</Typography>
      <Typography variant="body1">Check out our featured posts.</Typography>
      <FeaturedPosts />
    </Container>
  </Layout>
)

export default NotFoundPage